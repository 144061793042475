import React from 'react';
import Layout from '../layouts/index';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="intro">
          <div className="container">
            <h1>Seite nicht gefunden</h1>
            <p>Diese Seite existiert nicht mehr oder wurde verschoben.</p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
