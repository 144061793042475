import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

const Footer = props => (
  <div className="footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="footer">
            <div className="footer-contact">
            <h3 className="footer-title">{props.data.site.siteMetadata.title}</h3>
            <ul>
            <li>
                <strong>Tel: </strong>
                {props.data.site.siteMetadata.contact.phone}
              </li>
              <li>
                <strong>Email: </strong>
{' '}
                <a href={`mailto:${props.data.site.siteMetadata.contact.email}`}>
                  {props.data.site.siteMetadata.contact.email}
                </a>
              </li>
              <li>
                <strong>Anschrift: </strong>
                {props.data.site.siteMetadata.contact.street}
              </li>
              <li>

                {props.data.site.siteMetadata.contact.city}
              </li>
              </ul>
              </div>
            <ul className="footer-menu">
              <li>
                {' '}
                <Link to="/">Home</Link>
              </li>
              <li>
                {' '}
                <Link to="/impressum">Impressum</Link>
              </li>
              <li>
                {' '}
                <Link to="/das-unternehmen/datenschutzerklaerung">Datenschutzerklärung</Link>
              </li>
              <li>
                {' '}
                <Link to="/bildgroessenberechnung">Bildgrößenberechnung</Link>
              </li>
              <li>
                {' '}
                <Link to="/farbmuster">Farbmuster</Link>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            contact {
              email
              phone
              street
              city
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
